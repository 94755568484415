import React from 'react';
import Head from 'next/head';

type ComponentProps = {
  title: string;
  description: string;
  image: string;
  url: string;
};

const Heads: React.FC<ComponentProps> = ({ title, description, image, url }) => {
  return (
    <Head>
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:type' content='blog' />
      <meta property='og:url' content={url} />
      <meta property='og:image' content={image} />
      <meta property='og:site_name' content={title} />
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:site' content='@smalldish_jp' />
      <meta name='twitter:url' content={url} />
      <meta name='twitter:title' content={title} />
      <meta name='twitter:description' content={description} />
      <meta name='twitter:image' content={image} />
      <link rel='canonical' href={url} />
    </Head>
  );
};

export default Heads;
