import metaImage from 'public/img/contents/main-visual-about.jpg';

type MetaData = {
  title: string;
  description: string;
  image: string;
  url: string;
  path: string;
};

type MetaList = {
  [key: string]: MetaData;
};

export const SITE_TITLE = 'smalldish';
export const SITE_DESCRIPTION =
  'small dishは狭いキッチン向け献立メディア。「狭いキッチンでも作れる献立」を中心に「狭い空間で役立つアイデア集」「狭い空間でも活躍するアイテムの紹介」なども掲載しています。料理初心者・一人暮らしを始めた人・ミニマリストの方におすすめです。';

const pageTitle = (text: string) => `${text} | ${SITE_TITLE}`;
const pageDescription = (text: string) => `${text} | ${SITE_DESCRIPTION}`;

export const appURL = 'https://smalldish.jp';

export const metaList: MetaList = {
  index: {
    title: `${SITE_TITLE} | 狭いキッチン向け献立メディア`,
    description: SITE_DESCRIPTION,
    image: `${appURL}${metaImage}`,
    url: appURL,
    path: '/'
  },
  error: {
    title: pageTitle('Not Found'),
    description: SITE_DESCRIPTION,
    image: `${appURL}${metaImage}`,
    url: 'https://smalldish.jp/error',
    path: '/error'
  },
  menu: {
    title: pageTitle('献立'),
    description: pageDescription('献立ページ'),
    image: `${appURL}${metaImage}`,
    url: 'https://smalldish.jp/menus',
    path: '/menus'
  },
  menuCategoryCooking: {
    title: pageTitle('料理する人向け献立'),
    description: pageDescription('料理する人向け献立ページ'),
    image: `${appURL}${metaImage}`,
    url: 'https://smalldish.jp/menus-category/cooking-index',
    path: '/menus-category/cooking-index'
  },
  menuCategoryNoneCooking: {
    title: pageTitle('料理しない人向け献立'),
    description: pageDescription('料理しない人向け献立ページ'),
    image: `${appURL}${metaImage}`,
    url: 'https://smalldish.jp/menus-category/none-cooking-index',
    path: '/menus-category/none-cooking-index'
  },
  idea: {
    title: pageTitle('アイデア'),
    description: pageDescription('アイデアページ'),
    image: `${appURL}${metaImage}`,
    url: 'https://smalldish.jp/ideas',
    path: '/ideas'
  },
  shopping: {
    title: pageTitle('ショッピング'),
    description: pageDescription('ショッピングページ'),
    image: `${appURL}${metaImage}`,
    url: 'https://smalldish.jp/shoppings',
    path: '/shoppings'
  },
  favorite: {
    title: pageTitle('お気に入り'),
    description: pageDescription('お気に入りページ'),
    image: `${appURL}${metaImage}`,
    url: 'https://smalldish.jp/favorites',
    path: '/favorites'
  },
  login: {
    title: pageTitle('ログイン'),
    description: pageDescription('ログインページ'),
    image: `${appURL}${metaImage}`,
    url: 'https://smalldish.jp/logins',
    path: '/logins'
  },
  contact: {
    title: pageTitle('お問い合わせ'),
    description: pageDescription('お問い合わせページ'),
    image: `${appURL}${metaImage}`,
    url: 'https://smalldish.jp/contacts',
    path: '/contacts'
  },
  privacy: {
    title: pageTitle('プライバシー'),
    description: pageDescription('プライバシーページ'),
    image: `${appURL}${metaImage}`,
    url: 'https://smalldish.jp/privacies',
    path: '/privacies'
  },
  term: {
    title: pageTitle('利用規約'),
    description: pageDescription('利用規約ページ'),
    image: `${appURL}${metaImage}`,
    url: 'https://smalldish.jp/terms',
    path: '/terms'
  },
  about: {
    title: pageTitle('メディア紹介'),
    description: pageDescription('メディア紹介ページ'),
    image: `${appURL}${metaImage}`,
    url: 'https://smalldish.jp/about',
    path: '/about'
  }
};

export default metaList;
